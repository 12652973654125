import { EmptyRequest } from '../grpc/generated/appointment_pb';
import { AppointmentProtoServicePromiseClient } from '../grpc/generated/appointment_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new AppointmentProtoServicePromiseClient(apiUrl);

const token = window.localStorage.getItem("token");
const metadata = { Authorization: "Bearer " + token };

async function listAppointments() {
    const request = new EmptyRequest();
    const response = await svc.listAppointments(request, metadata, {});
    return response.toObject();
}

async function getAppointment(request) {
    const response = await svc.getAppointment(request, metadata, {});
    return response.toObject();
}

async function addAppointment(request) {
    const response = await svc.addAppointment(request, metadata, {});
    return response.toObject();
}

async function listPatientAppointments(request) {
    const response = await svc.listPatientAppointments(request, metadata, {});
    return response.toObject();
}

async function listPatientAppointmentDecision(request) {
    const response = await svc.listPatientAppointmentDecision(request, metadata, {});
    return response.toObject();
}

async function patientAppointmentDecision(request) {
    const response = await svc.patientAppointmentDecision(request, metadata, {});
    return response.toObject();
}

async function addAppointmentPatient(request) {
    const response = await svc.addAppointmentPatient(request, metadata, {});
    return response.toObject();
  }

export default {
    listAppointments,
    getAppointment,
    addAppointment,
    listPatientAppointments,
    listPatientAppointmentDecision,
    patientAppointmentDecision,
    addAppointmentPatient
}