/**
 * @fileoverview gRPC-Web generated client stub for appointmentApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: appointment.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.appointmentApi = require('./appointment_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.appointmentApi.AppointmentProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.AddStatusRequest,
 *   !proto.appointmentApi.AddStatusResponse>}
 */
const methodDescriptor_AppointmentProtoService_AddStatus = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/AddStatus',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.AddStatusRequest,
  proto.appointmentApi.AddStatusResponse,
  /**
   * @param {!proto.appointmentApi.AddStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.AddStatusResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.AddStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.AddStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.AddStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.addStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/AddStatus',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_AddStatus,
      callback);
};


/**
 * @param {!proto.appointmentApi.AddStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.AddStatusResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.addStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/AddStatus',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_AddStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.AddAppointmentPatientRequest,
 *   !proto.appointmentApi.AddAppointmentPatientResponse>}
 */
const methodDescriptor_AppointmentProtoService_AddAppointmentPatient = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/AddAppointmentPatient',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.AddAppointmentPatientRequest,
  proto.appointmentApi.AddAppointmentPatientResponse,
  /**
   * @param {!proto.appointmentApi.AddAppointmentPatientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.AddAppointmentPatientResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.AddAppointmentPatientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.AddAppointmentPatientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.AddAppointmentPatientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.addAppointmentPatient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/AddAppointmentPatient',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_AddAppointmentPatient,
      callback);
};


/**
 * @param {!proto.appointmentApi.AddAppointmentPatientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.AddAppointmentPatientResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.addAppointmentPatient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/AddAppointmentPatient',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_AddAppointmentPatient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.ListAppointmentsRequest,
 *   !proto.appointmentApi.ListAppointmentsResponse>}
 */
const methodDescriptor_AppointmentProtoService_ListAppointments = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ListAppointments',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.ListAppointmentsRequest,
  proto.appointmentApi.ListAppointmentsResponse,
  /**
   * @param {!proto.appointmentApi.ListAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ListAppointmentsResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.ListAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ListAppointmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ListAppointmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.listAppointments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListAppointments,
      callback);
};


/**
 * @param {!proto.appointmentApi.ListAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ListAppointmentsResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.listAppointments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListAppointments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.SearchCpfRequest,
 *   !proto.appointmentApi.SearchCpfResponse>}
 */
const methodDescriptor_AppointmentProtoService_SearchCpf = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/SearchCpf',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.SearchCpfRequest,
  proto.appointmentApi.SearchCpfResponse,
  /**
   * @param {!proto.appointmentApi.SearchCpfRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.SearchCpfResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.SearchCpfRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.SearchCpfResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.SearchCpfResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.searchCpf =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/SearchCpf',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_SearchCpf,
      callback);
};


/**
 * @param {!proto.appointmentApi.SearchCpfRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.SearchCpfResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.searchCpf =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/SearchCpf',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_SearchCpf);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.SearchEmailRequest,
 *   !proto.appointmentApi.SearchEmailResponse>}
 */
const methodDescriptor_AppointmentProtoService_SearchEmail = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/SearchEmail',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.SearchEmailRequest,
  proto.appointmentApi.SearchEmailResponse,
  /**
   * @param {!proto.appointmentApi.SearchEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.SearchEmailResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.SearchEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.SearchEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.SearchEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.searchEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/SearchEmail',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_SearchEmail,
      callback);
};


/**
 * @param {!proto.appointmentApi.SearchEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.SearchEmailResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.searchEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/SearchEmail',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_SearchEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.AddAppointmentProfessionalRequest,
 *   !proto.appointmentApi.AddAppointmentProfessionalResponse>}
 */
const methodDescriptor_AppointmentProtoService_AddAppointmentProfessional = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/AddAppointmentProfessional',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.AddAppointmentProfessionalRequest,
  proto.appointmentApi.AddAppointmentProfessionalResponse,
  /**
   * @param {!proto.appointmentApi.AddAppointmentProfessionalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.AddAppointmentProfessionalResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.AddAppointmentProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.AddAppointmentProfessionalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.AddAppointmentProfessionalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.addAppointmentProfessional =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/AddAppointmentProfessional',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_AddAppointmentProfessional,
      callback);
};


/**
 * @param {!proto.appointmentApi.AddAppointmentProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.AddAppointmentProfessionalResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.addAppointmentProfessional =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/AddAppointmentProfessional',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_AddAppointmentProfessional);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.VerifyConflictRequest,
 *   !proto.appointmentApi.VerifyConflictResponse>}
 */
const methodDescriptor_AppointmentProtoService_VerifyConflict = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/VerifyConflict',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.VerifyConflictRequest,
  proto.appointmentApi.VerifyConflictResponse,
  /**
   * @param {!proto.appointmentApi.VerifyConflictRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.VerifyConflictResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.VerifyConflictRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.VerifyConflictResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.VerifyConflictResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.verifyConflict =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyConflict',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyConflict,
      callback);
};


/**
 * @param {!proto.appointmentApi.VerifyConflictRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.VerifyConflictResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.verifyConflict =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyConflict',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyConflict);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.WeeklyScheduleRequest,
 *   !proto.appointmentApi.WeeklyScheduleResponse>}
 */
const methodDescriptor_AppointmentProtoService_WeeklySchedule = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/WeeklySchedule',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.WeeklyScheduleRequest,
  proto.appointmentApi.WeeklyScheduleResponse,
  /**
   * @param {!proto.appointmentApi.WeeklyScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.WeeklyScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.WeeklyScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.WeeklyScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.WeeklyScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.weeklySchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/WeeklySchedule',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_WeeklySchedule,
      callback);
};


/**
 * @param {!proto.appointmentApi.WeeklyScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.WeeklyScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.weeklySchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/WeeklySchedule',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_WeeklySchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.MonthlyScheduleRequest,
 *   !proto.appointmentApi.MonthlyScheduleResponse>}
 */
const methodDescriptor_AppointmentProtoService_MonthlySchedule = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/MonthlySchedule',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.MonthlyScheduleRequest,
  proto.appointmentApi.MonthlyScheduleResponse,
  /**
   * @param {!proto.appointmentApi.MonthlyScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.MonthlyScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.MonthlyScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.MonthlyScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.MonthlyScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.monthlySchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/MonthlySchedule',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_MonthlySchedule,
      callback);
};


/**
 * @param {!proto.appointmentApi.MonthlyScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.MonthlyScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.monthlySchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/MonthlySchedule',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_MonthlySchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.EmptyRequest,
 *   !proto.appointmentApi.ListWaitingListResponse>}
 */
const methodDescriptor_AppointmentProtoService_ListWaitingList = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ListWaitingList',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.EmptyRequest,
  proto.appointmentApi.ListWaitingListResponse,
  /**
   * @param {!proto.appointmentApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ListWaitingListResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ListWaitingListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ListWaitingListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.listWaitingList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListWaitingList',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListWaitingList,
      callback);
};


/**
 * @param {!proto.appointmentApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ListWaitingListResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.listWaitingList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListWaitingList',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListWaitingList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.RescheduleRequest,
 *   !proto.appointmentApi.RescheduleResponse>}
 */
const methodDescriptor_AppointmentProtoService_Reschedule = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/Reschedule',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.RescheduleRequest,
  proto.appointmentApi.RescheduleResponse,
  /**
   * @param {!proto.appointmentApi.RescheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.RescheduleResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.RescheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.RescheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.RescheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.reschedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/Reschedule',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_Reschedule,
      callback);
};


/**
 * @param {!proto.appointmentApi.RescheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.RescheduleResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.reschedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/Reschedule',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_Reschedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.VerifyHolidayRequest,
 *   !proto.appointmentApi.VerifyHolidayResponse>}
 */
const methodDescriptor_AppointmentProtoService_VerifyHoliday = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/VerifyHoliday',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.VerifyHolidayRequest,
  proto.appointmentApi.VerifyHolidayResponse,
  /**
   * @param {!proto.appointmentApi.VerifyHolidayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.VerifyHolidayResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.VerifyHolidayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.VerifyHolidayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.VerifyHolidayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.verifyHoliday =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyHoliday',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyHoliday,
      callback);
};


/**
 * @param {!proto.appointmentApi.VerifyHolidayRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.VerifyHolidayResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.verifyHoliday =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyHoliday',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyHoliday);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.VerifyScheduleRegistryRequest,
 *   !proto.appointmentApi.VerifyScheduleRegistryResponse>}
 */
const methodDescriptor_AppointmentProtoService_VerifyScheduleRegistry = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/VerifyScheduleRegistry',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.VerifyScheduleRegistryRequest,
  proto.appointmentApi.VerifyScheduleRegistryResponse,
  /**
   * @param {!proto.appointmentApi.VerifyScheduleRegistryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.VerifyScheduleRegistryResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.VerifyScheduleRegistryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.VerifyScheduleRegistryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.VerifyScheduleRegistryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.verifyScheduleRegistry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyScheduleRegistry',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyScheduleRegistry,
      callback);
};


/**
 * @param {!proto.appointmentApi.VerifyScheduleRegistryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.VerifyScheduleRegistryResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.verifyScheduleRegistry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyScheduleRegistry',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyScheduleRegistry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.PatientActionReschedulingRequest,
 *   !proto.appointmentApi.PatientActionReschedulingResponse>}
 */
const methodDescriptor_AppointmentProtoService_PatientActionRescheduling = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/PatientActionRescheduling',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.PatientActionReschedulingRequest,
  proto.appointmentApi.PatientActionReschedulingResponse,
  /**
   * @param {!proto.appointmentApi.PatientActionReschedulingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.PatientActionReschedulingResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.PatientActionReschedulingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.PatientActionReschedulingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.PatientActionReschedulingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.patientActionRescheduling =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/PatientActionRescheduling',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_PatientActionRescheduling,
      callback);
};


/**
 * @param {!proto.appointmentApi.PatientActionReschedulingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.PatientActionReschedulingResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.patientActionRescheduling =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/PatientActionRescheduling',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_PatientActionRescheduling);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.AddPatientWaitingListRequest,
 *   !proto.appointmentApi.AddPatientWaitingListResponse>}
 */
const methodDescriptor_AppointmentProtoService_AddPatientWaitingList = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/AddPatientWaitingList',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.AddPatientWaitingListRequest,
  proto.appointmentApi.AddPatientWaitingListResponse,
  /**
   * @param {!proto.appointmentApi.AddPatientWaitingListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.AddPatientWaitingListResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.AddPatientWaitingListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.AddPatientWaitingListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.AddPatientWaitingListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.addPatientWaitingList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/AddPatientWaitingList',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_AddPatientWaitingList,
      callback);
};


/**
 * @param {!proto.appointmentApi.AddPatientWaitingListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.AddPatientWaitingListResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.addPatientWaitingList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/AddPatientWaitingList',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_AddPatientWaitingList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.RescheduleAppointmentProfessionalRequest,
 *   !proto.appointmentApi.RescheduleAppointmentProfessionalResponse>}
 */
const methodDescriptor_AppointmentProtoService_RescheduleAppointmentProfessional = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/RescheduleAppointmentProfessional',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.RescheduleAppointmentProfessionalRequest,
  proto.appointmentApi.RescheduleAppointmentProfessionalResponse,
  /**
   * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.RescheduleAppointmentProfessionalResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.RescheduleAppointmentProfessionalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.RescheduleAppointmentProfessionalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.rescheduleAppointmentProfessional =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/RescheduleAppointmentProfessional',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_RescheduleAppointmentProfessional,
      callback);
};


/**
 * @param {!proto.appointmentApi.RescheduleAppointmentProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.RescheduleAppointmentProfessionalResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.rescheduleAppointmentProfessional =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/RescheduleAppointmentProfessional',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_RescheduleAppointmentProfessional);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.VerifyReschedulingRequest,
 *   !proto.appointmentApi.VerifyReschedulingResponse>}
 */
const methodDescriptor_AppointmentProtoService_VerifyRescheduling = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/VerifyRescheduling',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.VerifyReschedulingRequest,
  proto.appointmentApi.VerifyReschedulingResponse,
  /**
   * @param {!proto.appointmentApi.VerifyReschedulingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.VerifyReschedulingResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.VerifyReschedulingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.VerifyReschedulingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.VerifyReschedulingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.verifyRescheduling =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyRescheduling',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyRescheduling,
      callback);
};


/**
 * @param {!proto.appointmentApi.VerifyReschedulingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.VerifyReschedulingResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.verifyRescheduling =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyRescheduling',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyRescheduling);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.PatientReschedulingDecisionRequest,
 *   !proto.appointmentApi.PatientReschedulingDecisionResponse>}
 */
const methodDescriptor_AppointmentProtoService_PatientReschedulingDecision = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/PatientReschedulingDecision',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.PatientReschedulingDecisionRequest,
  proto.appointmentApi.PatientReschedulingDecisionResponse,
  /**
   * @param {!proto.appointmentApi.PatientReschedulingDecisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.PatientReschedulingDecisionResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.PatientReschedulingDecisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.PatientReschedulingDecisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.PatientReschedulingDecisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.patientReschedulingDecision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/PatientReschedulingDecision',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_PatientReschedulingDecision,
      callback);
};


/**
 * @param {!proto.appointmentApi.PatientReschedulingDecisionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.PatientReschedulingDecisionResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.patientReschedulingDecision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/PatientReschedulingDecision',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_PatientReschedulingDecision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.CancelAppointmentProfessionalRequest,
 *   !proto.appointmentApi.CancelAppointmentProfessionalResponse>}
 */
const methodDescriptor_AppointmentProtoService_CancelAppointmentProfessional = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/CancelAppointmentProfessional',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.CancelAppointmentProfessionalRequest,
  proto.appointmentApi.CancelAppointmentProfessionalResponse,
  /**
   * @param {!proto.appointmentApi.CancelAppointmentProfessionalRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.CancelAppointmentProfessionalResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.CancelAppointmentProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.CancelAppointmentProfessionalResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.CancelAppointmentProfessionalResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.cancelAppointmentProfessional =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/CancelAppointmentProfessional',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_CancelAppointmentProfessional,
      callback);
};


/**
 * @param {!proto.appointmentApi.CancelAppointmentProfessionalRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.CancelAppointmentProfessionalResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.cancelAppointmentProfessional =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/CancelAppointmentProfessional',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_CancelAppointmentProfessional);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.PatientAppointmentDecisionRequest,
 *   !proto.appointmentApi.PatientAppointmentDecisionResponse>}
 */
const methodDescriptor_AppointmentProtoService_PatientAppointmentDecision = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/PatientAppointmentDecision',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.PatientAppointmentDecisionRequest,
  proto.appointmentApi.PatientAppointmentDecisionResponse,
  /**
   * @param {!proto.appointmentApi.PatientAppointmentDecisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.PatientAppointmentDecisionResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.PatientAppointmentDecisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.PatientAppointmentDecisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.PatientAppointmentDecisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.patientAppointmentDecision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/PatientAppointmentDecision',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_PatientAppointmentDecision,
      callback);
};


/**
 * @param {!proto.appointmentApi.PatientAppointmentDecisionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.PatientAppointmentDecisionResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.patientAppointmentDecision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/PatientAppointmentDecision',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_PatientAppointmentDecision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.ListPatientAppointmentDecisionRequest,
 *   !proto.appointmentApi.ListPatientAppointmentDecisionResponse>}
 */
const methodDescriptor_AppointmentProtoService_ListPatientAppointmentDecision = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ListPatientAppointmentDecision',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.ListPatientAppointmentDecisionRequest,
  proto.appointmentApi.ListPatientAppointmentDecisionResponse,
  /**
   * @param {!proto.appointmentApi.ListPatientAppointmentDecisionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ListPatientAppointmentDecisionResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.ListPatientAppointmentDecisionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ListPatientAppointmentDecisionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ListPatientAppointmentDecisionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.listPatientAppointmentDecision =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListPatientAppointmentDecision',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListPatientAppointmentDecision,
      callback);
};


/**
 * @param {!proto.appointmentApi.ListPatientAppointmentDecisionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ListPatientAppointmentDecisionResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.listPatientAppointmentDecision =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListPatientAppointmentDecision',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListPatientAppointmentDecision);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.PatientCheckInRequest,
 *   !proto.appointmentApi.PatientCheckInResponse>}
 */
const methodDescriptor_AppointmentProtoService_PatientCheckIn = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/PatientCheckIn',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.PatientCheckInRequest,
  proto.appointmentApi.PatientCheckInResponse,
  /**
   * @param {!proto.appointmentApi.PatientCheckInRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.PatientCheckInResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.PatientCheckInRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.PatientCheckInResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.PatientCheckInResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.patientCheckIn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/PatientCheckIn',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_PatientCheckIn,
      callback);
};


/**
 * @param {!proto.appointmentApi.PatientCheckInRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.PatientCheckInResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.patientCheckIn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/PatientCheckIn',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_PatientCheckIn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.ConferCheckInRequest,
 *   !proto.appointmentApi.ConferCheckInResponse>}
 */
const methodDescriptor_AppointmentProtoService_ConferCheckIn = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ConferCheckIn',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.ConferCheckInRequest,
  proto.appointmentApi.ConferCheckInResponse,
  /**
   * @param {!proto.appointmentApi.ConferCheckInRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ConferCheckInResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.ConferCheckInRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ConferCheckInResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ConferCheckInResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.conferCheckIn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ConferCheckIn',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ConferCheckIn,
      callback);
};


/**
 * @param {!proto.appointmentApi.ConferCheckInRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ConferCheckInResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.conferCheckIn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ConferCheckIn',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ConferCheckIn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.ProfessionalCheckInRequest,
 *   !proto.appointmentApi.ProfessionalCheckInResponse>}
 */
const methodDescriptor_AppointmentProtoService_ProfessionalCheckIn = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ProfessionalCheckIn',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.ProfessionalCheckInRequest,
  proto.appointmentApi.ProfessionalCheckInResponse,
  /**
   * @param {!proto.appointmentApi.ProfessionalCheckInRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ProfessionalCheckInResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.ProfessionalCheckInRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ProfessionalCheckInResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ProfessionalCheckInResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.professionalCheckIn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ProfessionalCheckIn',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ProfessionalCheckIn,
      callback);
};


/**
 * @param {!proto.appointmentApi.ProfessionalCheckInRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ProfessionalCheckInResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.professionalCheckIn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ProfessionalCheckIn',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ProfessionalCheckIn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.UpdateQueueRequest,
 *   !proto.appointmentApi.UpdateQueueResponse>}
 */
const methodDescriptor_AppointmentProtoService_UpdateQueue = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/UpdateQueue',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.UpdateQueueRequest,
  proto.appointmentApi.UpdateQueueResponse,
  /**
   * @param {!proto.appointmentApi.UpdateQueueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.UpdateQueueResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.UpdateQueueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.UpdateQueueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.UpdateQueueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.updateQueue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/UpdateQueue',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_UpdateQueue,
      callback);
};


/**
 * @param {!proto.appointmentApi.UpdateQueueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.UpdateQueueResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.updateQueue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/UpdateQueue',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_UpdateQueue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.UpdateOrderQueueRequest,
 *   !proto.appointmentApi.UpdateOrderQueueResponse>}
 */
const methodDescriptor_AppointmentProtoService_UpdateOrderQueue = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/UpdateOrderQueue',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.UpdateOrderQueueRequest,
  proto.appointmentApi.UpdateOrderQueueResponse,
  /**
   * @param {!proto.appointmentApi.UpdateOrderQueueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.UpdateOrderQueueResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.UpdateOrderQueueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.UpdateOrderQueueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.UpdateOrderQueueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.updateOrderQueue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/UpdateOrderQueue',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_UpdateOrderQueue,
      callback);
};


/**
 * @param {!proto.appointmentApi.UpdateOrderQueueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.UpdateOrderQueueResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.updateOrderQueue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/UpdateOrderQueue',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_UpdateOrderQueue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.EmptyRequest,
 *   !proto.appointmentApi.VerifyQueueResponse>}
 */
const methodDescriptor_AppointmentProtoService_VerifyQueue = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/VerifyQueue',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.EmptyRequest,
  proto.appointmentApi.VerifyQueueResponse,
  /**
   * @param {!proto.appointmentApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.VerifyQueueResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.VerifyQueueResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.VerifyQueueResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.verifyQueue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyQueue',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyQueue,
      callback);
};


/**
 * @param {!proto.appointmentApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.VerifyQueueResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.verifyQueue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/VerifyQueue',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_VerifyQueue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.ListPatientsRequest,
 *   !proto.appointmentApi.ListPatientsResponse>}
 */
const methodDescriptor_AppointmentProtoService_ListPatients = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ListPatients',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.ListPatientsRequest,
  proto.appointmentApi.ListPatientsResponse,
  /**
   * @param {!proto.appointmentApi.ListPatientsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ListPatientsResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.ListPatientsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ListPatientsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ListPatientsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.listPatients =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListPatients',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListPatients,
      callback);
};


/**
 * @param {!proto.appointmentApi.ListPatientsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ListPatientsResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.listPatients =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListPatients',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListPatients);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.SearchListPatientsRequest,
 *   !proto.appointmentApi.SearchListPatientsResponse>}
 */
const methodDescriptor_AppointmentProtoService_SearchListPatients = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/SearchListPatients',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.SearchListPatientsRequest,
  proto.appointmentApi.SearchListPatientsResponse,
  /**
   * @param {!proto.appointmentApi.SearchListPatientsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.SearchListPatientsResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.SearchListPatientsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.SearchListPatientsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.SearchListPatientsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.searchListPatients =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/SearchListPatients',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_SearchListPatients,
      callback);
};


/**
 * @param {!proto.appointmentApi.SearchListPatientsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.SearchListPatientsResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.searchListPatients =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/SearchListPatients',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_SearchListPatients);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.ListPatientAppointmentsRequest,
 *   !proto.appointmentApi.ListPatientAppointmentsResponse>}
 */
const methodDescriptor_AppointmentProtoService_ListPatientAppointments = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ListPatientAppointments',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.ListPatientAppointmentsRequest,
  proto.appointmentApi.ListPatientAppointmentsResponse,
  /**
   * @param {!proto.appointmentApi.ListPatientAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ListPatientAppointmentsResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.ListPatientAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ListPatientAppointmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ListPatientAppointmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.listPatientAppointments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListPatientAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListPatientAppointments,
      callback);
};


/**
 * @param {!proto.appointmentApi.ListPatientAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ListPatientAppointmentsResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.listPatientAppointments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListPatientAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListPatientAppointments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.ListMedicalCareRequest,
 *   !proto.appointmentApi.ListMedicalCareResponse>}
 */
const methodDescriptor_AppointmentProtoService_ListMedicalCare = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ListMedicalCare',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.ListMedicalCareRequest,
  proto.appointmentApi.ListMedicalCareResponse,
  /**
   * @param {!proto.appointmentApi.ListMedicalCareRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ListMedicalCareResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.ListMedicalCareRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ListMedicalCareResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ListMedicalCareResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.listMedicalCare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListMedicalCare',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListMedicalCare,
      callback);
};


/**
 * @param {!proto.appointmentApi.ListMedicalCareRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ListMedicalCareResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.listMedicalCare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListMedicalCare',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListMedicalCare);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.GetStartPatientCareRequest,
 *   !proto.appointmentApi.GetStartPatientCareResponse>}
 */
const methodDescriptor_AppointmentProtoService_GetStartPatientCare = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/GetStartPatientCare',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.GetStartPatientCareRequest,
  proto.appointmentApi.GetStartPatientCareResponse,
  /**
   * @param {!proto.appointmentApi.GetStartPatientCareRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.GetStartPatientCareResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.GetStartPatientCareRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.GetStartPatientCareResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.GetStartPatientCareResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.getStartPatientCare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/GetStartPatientCare',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_GetStartPatientCare,
      callback);
};


/**
 * @param {!proto.appointmentApi.GetStartPatientCareRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.GetStartPatientCareResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.getStartPatientCare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/GetStartPatientCare',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_GetStartPatientCare);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.EmptyRequest,
 *   !proto.appointmentApi.ListPatientFutureAppointmentsResponse>}
 */
const methodDescriptor_AppointmentProtoService_ListPatientFutureAppointments = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ListPatientFutureAppointments',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.EmptyRequest,
  proto.appointmentApi.ListPatientFutureAppointmentsResponse,
  /**
   * @param {!proto.appointmentApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ListPatientFutureAppointmentsResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ListPatientFutureAppointmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ListPatientFutureAppointmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.listPatientFutureAppointments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListPatientFutureAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListPatientFutureAppointments,
      callback);
};


/**
 * @param {!proto.appointmentApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ListPatientFutureAppointmentsResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.listPatientFutureAppointments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListPatientFutureAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListPatientFutureAppointments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointmentApi.EmptyRequest,
 *   !proto.appointmentApi.ListNextMedicalCareResponse>}
 */
const methodDescriptor_AppointmentProtoService_ListNextMedicalCare = new grpc.web.MethodDescriptor(
  '/appointmentApi.AppointmentProtoService/ListNextMedicalCare',
  grpc.web.MethodType.UNARY,
  proto.appointmentApi.EmptyRequest,
  proto.appointmentApi.ListNextMedicalCareResponse,
  /**
   * @param {!proto.appointmentApi.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.appointmentApi.ListNextMedicalCareResponse.deserializeBinary
);


/**
 * @param {!proto.appointmentApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.appointmentApi.ListNextMedicalCareResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointmentApi.ListNextMedicalCareResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.appointmentApi.AppointmentProtoServiceClient.prototype.listNextMedicalCare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListNextMedicalCare',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListNextMedicalCare,
      callback);
};


/**
 * @param {!proto.appointmentApi.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointmentApi.ListNextMedicalCareResponse>}
 *     Promise that resolves to the response
 */
proto.appointmentApi.AppointmentProtoServicePromiseClient.prototype.listNextMedicalCare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/appointmentApi.AppointmentProtoService/ListNextMedicalCare',
      request,
      metadata || {},
      methodDescriptor_AppointmentProtoService_ListNextMedicalCare);
};


module.exports = proto.appointmentApi;

